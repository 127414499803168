import React from 'react';
import AppStateContext from '../Contexts/AppStateContext';
import { ColorPicker } from 'material-ui-color';
import FontIconPicker from '@fonticonpicker/react-fonticonpicker';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as allIcons from '@fortawesome/free-solid-svg-icons';
const { TableRow, TableCell } = require("@material-ui/core");

const iconNames = [];
Object.values(allIcons).forEach(icon => {
    if(icon.iconName) {
        iconNames.push(icon.iconName);
    }
})

const renderFontAwesome = fontAwesomeClass => (
	<FontAwesomeIcon icon={fontAwesomeClass} />
);

class Category extends React.Component {
    static contextType = AppStateContext;

    constructor(props) {
        super(props);
        this.state = {
            iconClass: 'business-time'
        };
    }

    onIconChange = (value) => {
        this.setState({...this.state, iconClass: value});
    };

    render() {
        return (<TableRow>
            <TableCell>
                {this.props.category.name}
            </TableCell>
            <TableCell align="right">
                <ColorPicker defaultValue="red" hideTextfield />
            </TableCell>
            <TableCell align="right">
                <FontIconPicker renderFunc={renderFontAwesome} icons={iconNames} value={this.state.iconClass} onChange={this.onIconChange} isMulti={false} closeOnSelect={true}/>
            </TableCell>
        </TableRow>);
    }
}

export default Category;