import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CategoryLabel from './CategoryLabel';
import AccountLabel from './AccountLabel';
import MoneyLabel from './MoneyLabel';
import AppStateContext from '../Contexts/AppStateContext';
const { TableRow, TableCell, IconButton, Checkbox } = require("@material-ui/core");

class Transaction extends React.Component {
    static contextType = AppStateContext;

    onEditClick = () => {
        this.context.editTransaction(this.props.transaction);
    };

    onDeleteClick = () => {
        this.context.deleteTransaction(this.props.transaction);
    };

    onClearChanged = () => {
        this.context.toggleCleared(this.props.transaction);
    }

    onWriteOffChanged = () => {
        this.context.toggleWriteOff(this.props.transaction);
    }

    getClassName = () => {
        if(!this.props.transaction.accountId || this.props.transaction.isCleared) {
            return "cleared";
        } else {
            return "pending";
        }
    };

    render() {
        return (<TableRow className={this.getClassName()}>
            <TableCell>
                {!this.context.isExpenseMode() && <Checkbox
                    checked={this.props.transaction.isCleared || !this.props.transaction.accountId}
                    disabled={!this.props.transaction.accountId}
                    onChange={this.onClearChanged}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />}
                {this.context.isExpenseMode() && <Checkbox
                    checked={this.props.transaction.isWrittenOff}
                    onChange={this.onWriteOffChanged}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }} />}
                <IconButton aria-label="edit" onClick={this.onEditClick}>
                    <EditIcon />
                </IconButton>
                <IconButton aria-label="delete" onClick={this.onDeleteClick}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
            <TableCell>
                {this.props.transaction.date}
            </TableCell>
            <TableCell align="left">{this.props.transaction.description}</TableCell>
            <TableCell align="right">
                <CategoryLabel categoryId={this.props.transaction.categoryId} />
            </TableCell>
            <TableCell align="right">
                <AccountLabel accountId={this.props.transaction.accountId}/>
            </TableCell>
            <TableCell align="right">
                <MoneyLabel amount={this.props.transaction.amount}/>
            </TableCell>
        </TableRow>);
    }
}

export default Transaction;