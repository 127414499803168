import React from 'react';
import { Box, /*Button,*/ Table, TableBody } from '@material-ui/core';
import Transaction from './Transaction';
import AppStateContext from '../Contexts/AppStateContext';

class TransactionsList extends React.Component {
    static contextType = AppStateContext;

    onMoreClicked = () => {
      this.context.viewMore();
    }

    render() {
      return (
        <Box>
          <Table className="transactions-list" size="small">
            <TableBody>
              {this.context.transactions?.map((transaction) => ( <Transaction key={transaction.id} transaction={transaction}/> ))}
            </TableBody>
          </Table>
        </Box>
      );
    }
}

export default TransactionsList;