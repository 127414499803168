import React from 'react';
import { Chip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppStateContext from '../Contexts/AppStateContext';

class AccountLabel extends React.Component {
    static contextType = AppStateContext;

    getAccount() {
        return this.props.accountId ? this.context.accounts?.find(account => account.id === this.props.accountId) : null;
    }

    render() {
        const account = this.getAccount();

        if(account){
            if(account.iconClass) {
                return (<Chip
                    style={{backgroundColor: account.color, color: "#ffffff"}}
                    icon={<FontAwesomeIcon icon={account.iconClass} style={{color: "#ffffff"}} />}
                    label={account.name}
                  />);
            } else {
                return (<Chip
                    label={account.name}
                  />);
            }
        } else {
            return null;
        }
    }
}

export default AccountLabel;