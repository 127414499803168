import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppStateContext from '../Contexts/AppStateContext';
import { Box } from '@material-ui/core';
import CategoriesList from './CategoriesList';

class CategoriesDialog extends React.Component {
    static contextType = AppStateContext;

    render = () => (
        <Box>
            <Dialog fullWidth aria-labelledby="form-dialog-title" open={false}>
                <DialogTitle id="form-dialog-title">Edit Categories</DialogTitle>
                <DialogContent>
                    <CategoriesList />
                </DialogContent>
                <DialogActions>
                    <Button color="primary">
                        Cancel
                    </Button>
                    <Button color="primary" type="submit">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default CategoriesDialog;