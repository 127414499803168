import React from 'react';
import { Chip } from '@material-ui/core';
import AppStateContext from '../Contexts/AppStateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CategoryLabel extends React.Component {
    static contextType = AppStateContext;

    getCategory() {
        return this.props.categoryId ? this.context.categories?.find(category => category.id === this.props.categoryId) : null;
    }

    render() {
        const category = this.getCategory();

        if(category){
            if(category.iconClass) {
                return (<Chip
                    style={{backgroundColor: category.color, color: "#ffffff"}}
                    icon={<FontAwesomeIcon icon={category.iconClass} style={{color: "#ffffff"}}/>}
                    label={category.name}
                  />);
            } else {
                return (<Chip
                    label={category.name}
                  />);
            }
        } else {
            return null;
        }
    }
}

export default CategoryLabel;