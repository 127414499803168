import React from 'react';
import { Table, TableBody } from '@material-ui/core';
import Category from './Category';
import AppStateContext from '../Contexts/AppStateContext';

class CategoriesList extends React.Component {
    static contextType = AppStateContext;

    render() {
      return (
        <Table className="categories-list" size="small">
          <TableBody>
            {this.context.categories.map((category) => ( <Category key={category.id} category={category}/> ))}
          </TableBody>
        </Table>
      );
    }
}

export default CategoriesList;