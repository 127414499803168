import React, { Component } from 'react';
import './App.scss';
import TransactionsList from './Components/TransactionsList.js';
import 'fontsource-roboto';
import { AppBar, Box, Toolbar } from '@material-ui/core';

//import SettingsMenu from './Components/SettingsMenu';
import MainMenu from './Components/MainMenu';
import { AppStateProvider } from './Contexts/AppStateContext';
import AppFooter from './Components/AppFooter';
import TransactionDialog from './Components/TransactionDialog';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import DeleteTransactionDialog from './Components/DeleteTransactionDialog';
import CategoriesDialog from './Components/CategoriesDialog';

import { library } from '@fortawesome/fontawesome-svg-core';
import * as allIcons from '@fortawesome/free-solid-svg-icons';
import LoginDialog from './Components/LoginDialog';

Object.values(allIcons).forEach(icon => {
    if(icon.icon) {
        library.add(icon);
    }
})

class App extends Component {
  render () {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AppStateProvider>
          <Box height="100vh">
            <AppBar position="fixed">
                <Toolbar>
                  <MainMenu />
                  {/*<SettingsMenu />*/}
                </Toolbar>
              </AppBar>
            <Box className="mainContent">
              <TransactionsList />
            </Box>
            <AppFooter />
          </Box>
          <TransactionDialog />
          <DeleteTransactionDialog />
          <CategoriesDialog />
          <LoginDialog />
        </AppStateProvider>
      </MuiPickersUtilsProvider>);
  }
}

export default App;
