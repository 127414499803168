import { Box, Button, Dialog, DialogActions, InputAdornment, TextField } from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
import React from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
var dateFormat = require("dateformat");

class InlineDateRangePicker extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        open: false,
        startDate: props.startDate,
        endDate: props.endDate,
        tempStartDate: props.startDate,
        tempEndDate: props.endDate
      };
    }

    getDateRange = () => {
        return dateFormat(this.state.startDate, "mediumDate") + " - " + dateFormat(this.state.endDate, "mediumDate");
    }
    
    onDateRangeChange = ranges => {
      this.setState({tempStartDate: ranges.selection.startDate, tempEndDate: ranges.selection.endDate});
    }

    onOpen = () => {
      if(!this.state.open) {
        this.setState({open: true, tempStartDate: this.state.startDate, tempEndDate: this.state.endDate});
      }
    }

    onCancel = () => {
      if(this.state.open) {
        this.setState({open: false});
      }
    }

    onUpdate = () => {
      if(this.state.open) {
        this.setState({open: false, startDate: this.state.tempStartDate, endDate: this.state.tempEndDate}, function() {
          this.props.onChange(this.state.startDate, this.state.endDate);
        });
      }
    }

    render() {
      return (
        <Box>
          <TextField style={{width: "300px"}} value={this.getDateRange()} InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <DateRange />
                </InputAdornment>
            ),
            }}
            onClick={this.onOpen} />
            <Dialog open={this.state.open} onClose={this.onCancel}>
              <DateRangePicker
                  onChange={this.onDateRangeChange}
                  ranges={[{startDate: this.state.tempStartDate, endDate: this.state.tempEndDate, key: 'selection'}]}
              />
              <DialogActions>
                <Button onClick={this.onCancel} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.onUpdate} color="primary">
                  Update
                </Button>
              </DialogActions>
            </Dialog>
        </Box>
      );
    }
}

export default InlineDateRangePicker;