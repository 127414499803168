import React from 'react';
import { Typography } from '@material-ui/core';

class MoneyLabel extends React.Component {

    render() {
        if(this.props.amount >= 0) {
            return <Typography className='positive'>${this.props.amount.toFixed(2)}</Typography>
        } else {
            return <Typography className='negative'>-${(-this.props.amount).toFixed(2)}</Typography>
        }
    }
}

export default MoneyLabel;