import React from 'react'
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles, IconButton, Typography, Select } from '@material-ui/core';
import AppStateContext from '../Contexts/AppStateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InlineDateRangePicker from './InlineDateRangePicker';
import { DatePicker } from '@material-ui/pickers';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

class MainMenu extends React.Component {
  static contextType = AppStateContext;

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      selectedText: "Joint Checking Ledger"
    };
  }

  setAnchorEl(anchorEl) {
    this.setState({...this.state, anchorEl});
  }

  handleClick(event) {
    this.setAnchorEl(event.currentTarget);
  };

  handleClose() {
    this.setAnchorEl(null);
  };

  onItemSelect(text, filter) {
    this.context.setTransactionFilter(filter);
    this.setState({...this.state, anchorEl: null, selectedText: text});
  }

  handleCategoryChange(e) {
    this.context.setTransactionFilter({
      categoryId: e.target.value === "" ? null : e.target.value
    });
  }
  
  render() {
    let that = this; 

    return (
      <>
        <IconButton edge="start" onClick={function(event) { that.handleClick(event); }} color="inherit" aria-controls="main-menu" aria-haspopup="true" variant="contained" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <StyledMenu
          id="customized-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={function() { that.handleClose(); }}
        > 
          {this.context.accounts?.map((account) => (
          <MenuItem key={account.id} value={account.id} onClick={function() {
            const initialEndDate = new Date();
            const initialStartDate = new Date();
            initialStartDate.setDate(initialStartDate.getDate()-30);
              that.onItemSelect(account.name + " Ledger", { transactionTypes: null, accountId: account.id, startDate: initialStartDate, endDate: initialEndDate });
            }}>
            <ListItemIcon>
              <FontAwesomeIcon icon={account.iconClass} />
            </ListItemIcon>
            <ListItemText>{account.name} Ledger</ListItemText>
          </MenuItem> ))}
          <MenuItem key="1000" onClick={function() {
            const year = (new Date()).getFullYear();
            const initialStartDate = new Date(year, 0, 1);
            const initialEndDate = new Date(year, 11, 31);

            that.onItemSelect("Business Expenses", { transactionTypes: [1], accountId: null, startDate: initialStartDate, endDate: initialEndDate });
            }}>
            <ListItemIcon>
              <FontAwesomeIcon icon="credit-card" />
            </ListItemIcon>
            <ListItemText primary="Business Expenses" />
          </MenuItem>
        </StyledMenu>
        <Typography variant="h6" className="title">
          {this.state.selectedText}
        </Typography>
        {!this.context.isExpenseMode() && <InlineDateRangePicker startDate={this.context.filter.startDate} endDate={this.context.filter.endDate} onChange={this.context.setDateRange} />}
        {this.context.isExpenseMode() && <><DatePicker
          maxDate={new Date()}
          minDate={new Date("2010-01-01")}
          views={['year']}
          value={this.context.filter.startDate}
          onChange={(newValue) => {
            const year = newValue.getFullYear();
            this.context.setDateRange(new Date(year, 0, 1), new Date(year, 11, 31));
          }}
        /><Select onChange={function(value) { that.handleCategoryChange(value); }} labelId="expense-category-label" id="expense-category" value={this.context.filter.categoryId ?? ""} displayEmpty>
        <MenuItem value="">
            <em>None</em>
        </MenuItem>
        {this.context.categories?.filter(category => category.transactionType === "Expense").map((category) => ( <MenuItem value={category.id} key={category.id}>{category.name}</MenuItem> ))}
    </Select></>}
    </>
    );
  }
}

export default MainMenu;