import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppStateContext from '../Contexts/AppStateContext';
import { Box, MenuItem, Select, InputLabel, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';

const defaultState = {
    transaction: {
        description: "",
        id: null,
        date: new Date().toISOString().substring(0, 10),
        amount: "",
        categoryId: null,
        accountId: null,
        isCleared: false
    },
    open: false,
    descriptionError: "",
    amountError: "",
    dateError: "",
    categoryError: ""
}

function shiftPickerDateToTZDate(pickerDate) {
    let pickerOffset = moment(pickerDate).utcOffset();
    let utcDate = new Date();
    utcDate.setTime(moment(pickerDate).valueOf() + pickerOffset * 60000);

    let tzOffset = moment.tz(pickerDate, moment.tz.guess()).utcOffset();
    let tzDate = new Date();
    tzDate.setTime(utcDate.getTime() - tzOffset * 60000);

    return tzDate;
}

function shiftTzDateToPickerDate(tzDate) {
    let utcDate = new Date();
    utcDate.setTime(moment(tzDate).valueOf());
  
    let pickerDate = new Date();
    let pickerOffset = pickerDate.getTimezoneOffset();
    pickerDate.setTime(utcDate.getTime() + pickerOffset * 60000);
  
    return pickerDate;
}

class TransactionDialog extends React.Component {
    static contextType = AppStateContext;

    constructor(props) {
        super(props);

        this.state = defaultState;
    }

    isTransactionValid = () => {
        return (this.state.transaction.accountId || this.state.transaction.categoryId) && this.state.transaction.date && this.state.transaction.description && this.state.transaction.amount;
    };

    reset = () => {
        this.setState(defaultState);
    };

    handleOpen = () => {
        if(this.context.selectedTransaction){
            this.setState({
                ...this.state,
                transaction: this.context.selectedTransaction
            });
        } else {
            this.setState({
                ...this.state,
                transaction: {
                    ...this.state.transaction,
                    accountId: this.context.getSelectedAccount()?.id
                }
            });
        }
    };

    handleClosed = () => {
        this.reset();
    };

    handleCancel = () => {
        this.context.editingTransactionComplete(null);
    };

    handleSubmit = () => {
        this.context.editingTransactionComplete(this.state.transaction);
    };

    updateTransaction = (updates, callback) => {
        this.setState({...this.state, transaction: { ...this.state.transaction, ...updates }}, callback);
    };

    handleDescriptionChange = (e) => {
        const description = e.target.value;
        this.updateTransaction({ description }, function() {
            if(description === "") {
                this.setState({ descriptionError: "Description is required" });
            }else{
                this.setState({ descriptionError: "" });
            }
        });
    };

    handleAccountChange = (e) => {
        this.updateTransaction({ accountId: e.target.value === "" ? null : e.target.value });
        if(!e.target.value && !this.state.transaction.categoryId) {
            this.setState({ categoryError: "A category or account must be specified." });
        } else {
            this.setState({ categoryError:"" });
        }
    };

    handleCategoryChange = (e) => {
        this.updateTransaction({ categoryId: e.target.value === "" ? null : e.target.value });
        if(!e.target.value && !this.state.transaction.accountId) {
            this.setState({ categoryError: "A category or account must be specified." });
        } else {
            this.setState({ categoryError:"" });
        }
    };

    handleDateChange = (date) => {
        let updatedDate = "";
        if (date instanceof Date && !isNaN(date)) {
            const shiftedDate = shiftPickerDateToTZDate(date);
            updatedDate = shiftedDate.toISOString().substring(0, 10);
        }

        this.updateTransaction({ date: updatedDate }, function() {
            if(updatedDate === "") {
                this.setState({ dateError: "Date is required" });
            } else {
                this.setState({ dateError: "" });
            }
        });
    };

    handleAmountChange = (_, amount) => {
        this.updateTransaction({ amount }, function() {
            if (!amount) {
                this.setState({ amountError: "Amount is required" });
            } else {
                this.setState({ amountError: "" });
            }
        });
    };

    handleClearChange = (e) => {
        this.updateTransaction({ isCleared: e.target.checked });
    };

    dialogTitle = () => {
        if(this.state.transaction.id) {
            return "Edit Transaction";
        }

        return "Add Transaction";
    };

    submitButtonLabel = () => {
        if(this.state.transaction.id) {
            return "Update";
        }

        return "Create";
    };

    isCleared = () => {
        if(!this.state.transaction.accountId) {
            return true;
        }

        return this.state.transaction.isCleared;
    };

    render = () => (
        <Box>
            <Dialog fullWidth onEnter={this.handleOpen} onExited={this.handleClosed} open={this.context.editingTransaction} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{this.dialogTitle()}</DialogTitle>
                <DialogContent>
                    <TextField error={this.state.descriptionError} helperText={this.state.descriptionError} autoFocus required onChange={this.handleDescriptionChange} margin="dense" id="description" label="Description" value={this.state.transaction.description} fullWidth/>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <InputLabel shrink id="account-label">Checking Account</InputLabel>
                            <Select fullWidth onChange={this.handleAccountChange} labelId="account-label" id="account" value={this.state.transaction.accountId ?? ""} displayEmpty>
                                <MenuItem value="">
                                    <em>None (Credit Card)</em>
                                </MenuItem>
                                {this.context.accounts?.map((account) => ( <MenuItem value={account.id} key={account.id}>{account.name}</MenuItem> ))}
                            </Select>
                        </Grid>
                        <Grid item xs={2} className={this.state.transaction.accountId ? "" : "hide"}>
                            <FormControlLabel
                            className="clearedCheck"
                            control={
                                <Checkbox
                                    checked={this.isCleared()}
                                    onChange={this.handleClearChange}
                                    name="isCleared"
                                    color="primary"
                                    disabled={!this.state.transaction.accountId}
                                />
                                }
                                label="Cleared"
                            />
                        </Grid>
                    </Grid>
                    <InputLabel shrink id="expense-category-label">Expense Category</InputLabel>
                    <Select error={this.state.categoryError} helperText={this.state.categoryError} fullWidth onChange={this.handleCategoryChange} labelId="expense-category-label" id="expense-category" value={this.state.transaction.categoryId ?? ""} displayEmpty>
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {this.context.categories?.filter(category => category.transactionType === "Expense").map((category) => ( <MenuItem value={category.id} key={category.id}>{category.name}</MenuItem> ))}
                    </Select>
                    {this.state.categoryError && (<div style={{ fontSize: '12px', color: 'red' }}>{this.state.categoryError}</div>)}
                    <CurrencyTextField error={this.state.amountError} helperText={this.state.amountError} required textAlign="left" onChange={this.handleAmountChange} margin="dense" id="amount" label="Amount" value={this.state.transaction.amount} fullWidth/>
                    <KeyboardDatePicker
                        required
                        error={this.state.dateError} helperText={this.state.dateError} 
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Transaction Date"
                        value={shiftTzDateToPickerDate(new Date(this.state.transaction.date))}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        autoOk={true}
                        onChange={this.handleDateChange}
                        />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary" type="submit" disabled={!this.isTransactionValid()}>
                        {this.submitButtonLabel()}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default TransactionDialog;