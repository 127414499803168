import React from 'react';
import AppStateContext from '../Contexts/AppStateContext';
import { AppBar, Toolbar, Fab, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

class AppFooter extends React.Component {
    static contextType = AppStateContext;

    render() {
        const selectedAccount = this.context.getSelectedAccount(); 

        if(selectedAccount) {
            return (<AppBar className="bottomAppBar">
            <Toolbar>
              <Fab aria-label="add" className="addTransactionButton" color="secondary" onClick={() => this.context.editTransaction(null)}><AddIcon /></Fab>
              <Typography variant="h6">
                Balance: ${selectedAccount.total.toFixed(2)} (Posted: ${selectedAccount.clearedTotal.toFixed(2)})
              </Typography>
            </Toolbar>
          </AppBar>);
        }
        else if(this.context.hasFilter())
        {
            return (<AppBar className="bottomAppBar">
            <Toolbar>
              <Fab aria-label="add" className="addTransactionButton" color="secondary" onClick={() => this.context.editTransaction(null)}><AddIcon /></Fab>
              <Typography variant="h6">
                  Total: ${this.context.getTransactionsTotal().toFixed(2)}
              </Typography>
              </Toolbar>
            </AppBar>);
        }
        else
        {
          return (<AppBar className="bottomAppBar">
            <Toolbar>
              <Fab aria-label="add" className="addTransactionButton" color="secondary" onClick={() => this.context.editTransaction(null)}><AddIcon /></Fab>
              <Typography variant="h6">
                  
              </Typography>
              </Toolbar>
            </AppBar>);
        }
    }
}

export default AppFooter;