import React from 'react';
import ConfirmationDialog from './ConfirmationDialog';
import AppStateContext from '../Contexts/AppStateContext';

class DeleteTransactionDialog extends React.Component {
    static contextType = AppStateContext;

    onConfirm = () => {
        this.context.deletingTransactionComplete(true);
    };

    onCancel = () => {
        this.context.deletingTransactionComplete(false);
        console.log('on cancel');
    };

    render() {
        return (
        <ConfirmationDialog open={this.context.deletingTransaction} title="Delete Transaction" onCancel={this.onCancel} onConfirm={this.onConfirm}>
            Are you sure you want to delete '{this.context.selectedTransaction?.description}'?
        </ConfirmationDialog>);
    }
}

export default DeleteTransactionDialog;
