import React, { Component } from 'react'

const rootUrl = process.env.NODE_ENV === 'production' ? 'https://api.lookatmycode.com/' : 'https://localhost:44366/';

const initialEndDate = new Date();
const initialStartDate = new Date();
initialStartDate.setDate(initialStartDate.getDate()-30);

let initialLoad = true;

const defaultState = {
    filter: {
      accountId: null,
      transactionTypes: null,
      startDate: initialStartDate,
      endDate: initialEndDate,
      categoryId: null
    },
    transactions: [],
    accounts: [],
    categories: [],
    setSelectedAccount: { },
    getSelectedAccount: { },
    hasFilter: { },
    isExpenseMode: { },
    getTransactionsTotal: { },
    selectedTransaction: null,
    editingTransaction: false,
    deletingTransaction: false,
    editTransaction: { },
    deleteTransaction: { },
    toggleCleared: { },
    toggerWriteOff: { },
    editingTransactionComplete: { },
    deletingTransactionComplete: { },
    getAccessToken: { },
    login: { }
};

const AppStateContext = React.createContext(defaultState);

class AppStateProvider extends Component {
  constructor(props) {
    super(props);

    this.handleUncaughtError = (response) => {
      console.log(response);
    }

    this.handleResponse = (response) => {
      if(response.status === 204) {
        return;
      }
      else if(response.status < 400) {
        return response.json();
      } else if(response.status === 401) {
        this.logout();
      } else {
        throw response;
      }
    }

    this.logout = () => {
      this.setAccessToken(null);
    }

    this.editTransaction = (transaction) => {
      this.setState({...this.state, selectedTransaction: transaction, editingTransaction: true});
    };

    this.deleteTransaction = (transaction) => {
      this.setState({...this.state, selectedTransaction: transaction, deletingTransaction: true});
    };

    this.deletingTransactionComplete = async (confirmDelete) => {
      if(confirmDelete) {
        fetch(rootUrl + 'transactions/' + this.state.selectedTransaction.id,
          {
            method: 'DELETE',
            headers: {
              'Authorization': 'Bearer ' + this.getAccessToken()
            }
          })
          .then(response => this.handleResponse(response))
          .then(() => this.setState({...this.state, deletingTransaction: false, selectedTransaction: null}))
          .then(() => { this.loadTransactions(); this.loadAccounts(); })
          .catch(this.handleUncaughtError);
      }
    };

    this.editingTransactionComplete = async (transaction) => {
      if(transaction) {
        let action = null;
        if(transaction.id) {
          action = fetch(rootUrl + 'transactions/' + this.state.selectedTransaction.id,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAccessToken()
              },
              body: JSON.stringify(transaction)
            });
        } else {
          action = fetch(rootUrl + 'transactions',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAccessToken()
              },
              body: JSON.stringify(transaction)
            });
        }

        action
          .then(response => this.handleResponse(response))
          .then(() => { this.loadTransactions(); this.loadAccounts(); })
          .catch(this.handleUncaughtError);
      }

      this.setState({...this.state, selectedTransaction: null, editingTransaction: false});
    };

    this.toggleCleared = (transaction) => {
      transaction.isCleared = !transaction.isCleared;
      fetch(rootUrl + 'transactions/' + transaction.id,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAccessToken()
              },
              body: JSON.stringify(transaction)
            })
            .then(response => this.handleResponse(response))
            .then(() => {this.loadTransactions(); this.loadAccounts(); })
            .catch(this.handleUncaughtError);
    };

    this.toggleWriteOff = (transaction) => {
      transaction.isWrittenOff = !transaction.isWrittenOff;
      fetch(rootUrl + 'transactions/' + transaction.id,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAccessToken()
              },
              body: JSON.stringify(transaction)
            })
            .then(response => this.handleResponse(response))
            .then(() => {this.loadTransactions(); })
            .catch(this.handleUncaughtError);
    };

    this.viewMore = () => {
      const newStartDate = this.state.filter?.startDate;
      newStartDate.setDate(newStartDate.getDate() - 30);
      this.setTransactionFilter({...this.state.filter, startDate: newStartDate});
    };

    this.setDateRange = (startDate, endDate) => {
      this.setTransactionFilter({...this.state.filter, startDate, endDate});
    };

    this.setTransactionFilter = (filter) => {
      if(filter) {
        if(filter.startDate === undefined) {
          filter.startDate = this.state.filter?.startDate;
        }

        if(filter.accountId === undefined) {
          filter.accountId = this.state.filter?.accountId;
        }

        if(filter.transactionTypes === undefined) {
          filter.transactionTypes = this.state.filter?.transactionTypes;
        }

        if(filter.categoryId === undefined) {
          filter.categoryId = this.state.filter?.categoryId;
        }
      }

      this.setState({...this.state, filter }, () => {
        this.loadTransactions();
      });
    };

    this.getSelectedAccount = () => {
      if(this.state.filter?.accountId){
        return this.state.accounts.find(account => account.id === this.state.filter.accountId);
      }

      return null;
    };

    this.isExpenseMode = () => {
      return this.state.filter.accountId === null &&
            this.state.filter.transactionTypes?.length === 1 &&
            this.state.filter.transactionTypes[0] === 1; 
    }

    this.hasFilter = () => {
      return this.state.filter?.transactionTypes != null;
    }

    this.getTransactionsTotal = () => {
      let total = 0;
      if(this.state.transactions) {
        this.state.transactions.forEach(transaction => total += transaction.amount);
      }
      return total;
    };

    this.getAccessToken = () => {
      return localStorage.getItem('accessToken') === "null" ? null : localStorage.getItem('accessToken');
    }

    this.setAccessToken = (accessToken) => {
      localStorage.setItem("accessToken", accessToken);
    }

    this.state = {
      filter: defaultState.filter,
      transactions: defaultState.transactions,
      accounts: defaultState.accounts,
      categories: defaultState.categories,
      setTransactionFilter: this.setTransactionFilter,
      viewMore: this.viewMore,
      setDateRange: this.setDateRange,
      getSelectedAccount: this.getSelectedAccount,
      hasFilter: this.hasFilter,
      isExpenseMode: this.isExpenseMode,
      getTransactionsTotal: this.getTransactionsTotal,
      selectedTransaction: null,
      editingTransaction: false,
      deletingTransaction: false,
      editTransaction: this.editTransaction,
      editingTransactionComplete: this.editingTransactionComplete,
      deleteTransaction: this.deleteTransaction,
      deletingTransactionComplete: this.deletingTransactionComplete,
      toggleCleared: this.toggleCleared,
      toggleWriteOff: this.toggleWriteOff,
      getAccessToken: this.getAccessToken,
      setAccessToken: this.setAccessToken,
      login: this.login
    }
  }

  componentDidMount(prevProps) {
    this.loadTransactions();
    this.loadAccounts();
    this.loadCategories();
  }

  loadTransactions = async () => {
    let url = rootUrl + "transactions";

    let queryParams = [];

    if(this.state.filter?.accountId) {
      queryParams.push("accountId=" + this.state.filter.accountId);
    }

    if(this.state.filter?.transactionTypes) {
      this.state.filter.transactionTypes.forEach(value => queryParams.push("types=" + value));
    }

    if(this.state.filter?.startDate) {
      queryParams.push("startDate=" + this.state.filter.startDate.toISOString().split('T')[0]);
    }

    if(this.state.filter?.endDate) {
      queryParams.push("endDate=" + this.state.filter.endDate.toISOString().split('T')[0]);
    }

    if(this.state.filter?.categoryId) {
      queryParams.push("categoryId=" + this.state.filter.categoryId);
    }

    if(queryParams.length > 0) {
      url += "?" + queryParams.join("&");
    }

    fetch(url,
      {
        headers: {
          'Authorization': 'Bearer ' + this.getAccessToken()
        }
      })
      .then(response => this.handleResponse(response))
      .then(data => this.setState({...this.state, transactions: data}))
      .catch(this.handleUncaughtError);
  }

  loadAccounts = async () => {
    fetch(rootUrl + 'accounts',
      {
        headers: {
          'Authorization': 'Bearer ' + this.getAccessToken()
        }
      })
      .then(response => this.handleResponse(response))
      .then(data => {
        const defaultFilter = {
          accountId: data[0].id,
          transactionTypes: null,
          startDate: initialStartDate,
          endDate: initialEndDate
        }

        const filter = initialLoad ? defaultFilter : this.state.filter;
        initialLoad = false;

        this.setState({...this.state, accounts: data, filter }, () => {
          this.loadTransactions();
        });
      })
      .catch(this.handleUncaughtError);
  }

  loadCategories = async () => {
    fetch(rootUrl + 'transactioncategories',
      {
        headers: {
          'Authorization': 'Bearer ' + this.getAccessToken()
        }
      })
      .then(response => this.handleResponse(response))
      .then(data => this.setState({...this.state, categories: data}))
      .catch(this.handleUncaughtError);
  }

  login = async (event) => {
    fetch(rootUrl + 'token', 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(event)
    })
    .then(response => response.json())
    .then((response) => {
      if(response.accessToken) {
        this.setAccessToken(response.accessToken);
        
        this.loadTransactions();
        this.loadCategories();
        this.loadAccounts();
      } else {
        alert("Invalid username or password.");
      }
    })
  }

  render() {
    const { children } = this.props

    return (
      <AppStateContext.Provider
        value={{
          ...this.state
        }}
      >
        {children}
      </AppStateContext.Provider>
    )
  }
}

export default AppStateContext

export { AppStateProvider }